import React, { useRef, useState, useMemo } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame } from 'react-three-fiber'
import { OrbitControls, Stars, Text, meshBounds } from '@react-three/drei'

function Tetrahedron(props) {
  const mesh = useRef()

  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)

  useFrame(() => {
    // Rotate mesh every frame
    mesh.current.rotation.y += 0.01
    mesh.current.rotation.z += 0.001
  })

  const vertices = [
    // Bottom face
    -1, -1, -1,
    1, -1, -1,
    0, -1, 1,
    // Top face
    0.0, 0.816496580927726, -0.2
  ];

  const indices = [
    // Bottom face
    0, 1, 2,
    // Front face
    3, 1, 0,
    // Right face
    3, 2, 1,
    // Back face
    3, 0, 2
  ];

  const geometry = useMemo(() => {
    const geo = new THREE.BufferGeometry()
    geo.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))
    geo.setIndex(indices)
    geo.computeVertexNormals()
    return geo
  }, [])

  return (
    <mesh
      {...props}
      ref={mesh}
      raycast={meshBounds}
      //scale={active ? [1.5, 1.5, 1.5] : [1, 1, 1] }
      onClick={(e) => setActive(!active)}
      onPointerOver={(e) => setHover(true)}
      onPointerOut={(e) => setHover(false)}
      geometry={geometry}
      >
       <meshPhongMaterial color="#f3f3f3" wireframe />
       {/*<meshStandardMaterial metalness={0.5} roughness={0.7} attach="material" color='#17798C' />*/}
    </mesh>
  )
}

export default function Home() {
  return (
    <div style={{ width: "100vw", height: "100vh", backgroundColor: '#000000' }}>
    <Canvas>
      <ambientLight intensity={1} />
      <spotLight position={[10, 10, 10]} angle={0.15} />
      <Tetrahedron position={[0, 1, 0]} />
      <Text
        scale={[1, 1, 1]}
        color="white" // default
        anchorX="center" // default
        anchorY="middle" // default
        position={[0, -1.3, 0]}
      >
        SADWARE
      </Text>
      <OrbitControls 
        autoRotate
        autoRotateSpeed={0.2}
      />

      <Stars
        radius={100} // Radius of the inner sphere (default=100)
        depth={50} // Depth of area where stars should fit (default=50)
        count={5000} // Amount of stars (default=5000)
        factor={4} // Size factor (default=4)
        saturation={0} // Saturation 0-1 (default=0)
        fade // Faded dots (default=false)
      />
    </Canvas>
    </div>
  )
}