import { Route, Routes, Navigate } from "react-router-dom";
// import './App.css';
import Home from './Home';
import PrivacyPolicy from './BrowDesigner/PrivacyPolicy';
import Terms from './BrowDesigner/Terms';
import Main from './BrowDesigner/Main';
import Redirect from './BrowDesigner/Redirect'

function App() {
  const url = "https://apps.apple.com/us/app/brow-designer/id6446804452"
  return (
    <Routes>
      <Route path='/' element={Home()} />
      <Route path='/browdesigner' element={Main()} />
      <Route path='/browdesigner/privacypolicy' element={PrivacyPolicy()} />
      <Route path='/browdesigner/terms' element={Terms()} />
      <Route path='/browdesigner/app'
        element={<Redirect url={url}/>}
      />
    </Routes>
  );
}

export default App;
