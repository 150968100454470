import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaApple } from "react-icons/fa";

function Main() {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <header className="bg-white py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Brow Designer</h1>
          <h1 className="text-medium font-thin text-gray-900">The ultimate brow shaping tool</h1>
        </div>
      </header>

      {/* Main */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

          {/* App Description */}
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-bold text-gray-900">Brow Designer</h2>
            <p className="text-gray-700 mt-4">
              Our AI-powered app quickly positions eyebrows on a face, then allows you to customize the size, shape, and position to create your desired look. With Brow Designer, you'll enjoy expert-level brow shaping that's personalized, accurate, and effortless.
            </p>
            <div className="mt-6 flex items-center">
              <a
                href="https://apps.apple.com/us/app/brow-designer/id6446804452"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
              >
                <FaApple className="mr-2" />
                Download on the App Store
              </a>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-bold text-gray-900">Contact Support</h2>
            <p className="text-gray-700 mt-4">
              Our goal is to help you quickly and confidently design beautiful eyebrows. For any questions, suggestions, or feedback then please reach out at the email address below.
            </p>
            <div className="mt-6 flex items-center">
              <a href="mailto:BrowDesigner@sadware.com" class="text-blue-500 hover:text-blue-600">BrowDesigner@sadware.com</a>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-white py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-gray-500 text-sm text-center">© 2023 SADWARE INCORPOATED. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Main;
